import { getSTSToken } from "../api/oss";
import { ElMessage } from 'element-plus'
import OSS from 'ali-oss'
let client
const OSS_REGION = 'oss-cn-hangzhou'
const OSS_BUCKET = 'westlake'
const REFRESH_TOKEN_INTERVAL = 3600 * 1000 // 1小时刷新token时间
const userId = sessionStorage.getItem("userId")
const search = new URLSearchParams(location.search)
const id =  search.get('id')

export const getOssInfo = async () => {
    console.log(id, 'id')
    const res = await getSTSToken(id)
    if (res.code === 0) {
        const {
            accessKeyId,
            accessKeySecret,
            securityToken,
            expiration
        } = res.data

        const config = {
            accessKeyId,
            accessKeySecret,
            stsToken: securityToken,
        }
        initClient(config)
        return config

    } else return false
}
const initClient = (ossconfig) => {
    // Top Level await 无法使用
    // 初始化的时候先获取token信息
    console.log(ossconfig)
    client = new OSS({
        region: OSS_REGION,
        bucket: OSS_BUCKET,
        ...ossconfig,
        refreshSTSToken: refreshToken(ossconfig),
        refreshSTSTokenInterval: REFRESH_TOKEN_INTERVAL
    })
}
const refreshToken = async (ossconfig) => {
    return {
        accessKeyId: ossconfig.accessKeyId,
        accessKeySecret: ossconfig.accessKeySecret,
        stsToken: ossconfig.stsToken
    }
}
export const getFileNameUUID = () => {
    const rx = () =>
        (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    return `${+new Date()}_${rx()}${rx()}`
}

export const createNewFileName = (sourceFileName = '') => {

    // eslint-disable-next-line
    const reg = /\.[^\.]+$/
    const suffix = sourceFileName.match(reg)
    return `${getFileNameUUID()}${suffix ? suffix[0] : ''}`
}
/**
 *
 * @param {*} param
 * type 文件类型 image/audio 两种，用来存放不同文件夹下  TODO 是否将文件类型判断放在该函数中
 * fileName 文件名
 * data 上传的 File/Blob/OSS Buffer内容（OSS.Buffer('hello oss')） 类型的文件
 * showErrorMsg 是否展示上传失败的提示内容
 * @returns
 */
async function putFile({
    type = 'image',
    fileName = createNewFileName(file.name),
    file,
    showErrorMsg = true
}) {
    if (!file) return
    try {
        const result = await client.put(`${type}/${userId}/${fileName}`, file)
        return result
    } catch (e) {
        if (showErrorMsg) {
            ElMessage.error({
                message: e.message || '文件上传失败',
                type: 'warning'
            })
        }
    }
}
export default putFile
